// import { useLocation, useNavigate } from 'react-router-dom';
// import React, { useEffect } from 'react';
// import Config from '../../../helpers/Config';
// import rootAction from '../../../stateManagment/actions/rootAction'
// import { useSelector, useDispatch } from 'react-redux';

// export default function PayUFailResponse() {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [cartProductsData, setCartProductsData] = useState(0);
//     const [CouponCode, setCouponCode] = useState('');
//     const location = useLocation();

//     // Function to parse query parameters
//     const getQueryParams = () => {
//         const query = new URLSearchParams(location.search);
//         const params = {};
//         query.forEach((value, key) => {
//             params[key] = value;
//         });
//         return params;
//     };

//     useEffect(async () => {
//         const params = getQueryParams();
//         console.log("Query Parameters:", params.status);
//         console.log("Query Parameters:", params.unmappedstatus);
//         // Handle any logic based on the params if needed


//         dispatch(rootAction.commonAction.setLoading(true));
//         await PlaceAndConfirmCustomerOrder(null);
//         dispatch(rootAction.commonAction.setLoading(false));
//     }, [location]);




//     const PlaceAndConfirmCustomerOrder = async () => {
//         try {
//             const headersStrip = {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//             };

//             const paramSrip = {
//                 requestParameters: {
//                     UserID: loginUser.UserID,
//                     OrderNote: OrderNote,
//                     cartJsonData: JSON.stringify(cartItemsSession),
//                     CouponCode: IsCouponCodeApplied ? CouponCode : "",
//                     PaymentMethod: 6,
//                     paymentToken:  "",
//                     payPalOrderConfirmJson: "",
//                     recordValueJson: "[]",
//                 },
//             };

//             const stripServerResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['POST_CUSTOMER_ORDER'], Config['COMMON_CONTROLLER_SUB_URL'], paramSrip, headersStrip, "POST", true);
//             if (stripServerResponse != null && stripServerResponse.data != null && stripServerResponse.status === 200) {
//                 let stripServerResponseDetail = JSON.parse(stripServerResponse.data.data ? stripServerResponse.data.data : "[]");

//                 if (stripServerResponseDetail.length > 0 && stripServerResponseDetail[0].ResponseMsg === "Order Placed Successfully") {
//                     showSuccessMsg("Order Placed Successfully!");

//                     setTimeout(() => {
//                         navigate('/' + getLanguageCodeFromSession() + '/');
//                         dispatch(rootAction.cartAction.setCustomerCart('[]'));
//                         dispatch(rootAction.cartAction.SetTotalCartItems(0));
//                         localStorage.setItem("cartItems", "[]");
//                     }, 1000);
//                 } else {
//                     showErrorMsg("An error occurred. Please try again!");
//                 }
//             } else {
//                 showErrorMsg("An error occurred. Please try again!");
//             }

//             if (PaymentMethod === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
//                 HandleStripCardModal();
//             } else if (PaymentMethod === process.env.REACT_APP_PAYPAL_PAYMENT_METHOD) {
//                 HandlePaypalCardModal();
//             }
//         } catch (err) {
//             showErrorMsg("An error occurred. Please try again!");
//             console.log(err.message);
//             if (PaymentMethod === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
//                 HandleStripCardModal();
//             }
//             dispatch(rootAction.commonAction.setLoading(false));
//         }
//     };

//     return (
//         <div style={{ textAlign: 'center', padding: '50px' }}>
//             <h1>Payment Failed</h1>
//             <p>Unfortunately, your payment could not be processed. Please try again.</p>
//             <p>You will be redirected shortly. If not, click <button onClick={() => navigate('/')}>here</button>.</p>
//         </div>
//     );
// }



// import { useLocation, useNavigate } from 'react-router-dom';
// import React, { useEffect } from 'react';
// import Config from '../../../helpers/Config';
// import rootAction from '../../../stateManagment/actions/rootAction';
// import { useSelector, useDispatch } from 'react-redux';

// export default function PayUFailResponse() {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const location = useLocation();

//     // Function to parse query parameters
//     const getQueryParams = () => {
//         const query = new URLSearchParams(location.search);
//         const params = {};
//         query.forEach((value, key) => {
//             params[key] = value;
//         });
//         return params;
//     };

//     useEffect(() => {
//         const params = getQueryParams();
//         console.log("Query Parameters:", params.status);
//         console.log("Query Parameters:", params.unmappedstatus);

//         // Set loading state
//         dispatch(rootAction.commonAction.setLoading(true));

//         // Place and confirm the customer order
//         PlaceAndConfirmCustomerOrder();

//         // Reset loading state after processing
//         dispatch(rootAction.commonAction.setLoading(false));
//     }, [location]);

//     const PlaceAndConfirmCustomerOrder = async () => {
//         try {
//             const headers = {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//             };

//             const paramSrip = {
//                 requestParameters: {
//                     UserID: loginUser.UserID,
//                     OrderNote: OrderNote,
//                     cartJsonData: JSON.stringify(cartItemsSession),
//                     CouponCode: IsCouponCodeApplied ? CouponCode : "",
//                     PaymentMethod: 6, // Assuming 6 corresponds to Cash on Delivery
//                     paymentToken: "",
//                     payPalOrderConfirmJson: "",
//                     recordValueJson: "[]",
//                 },
//             };

//             const stripServerResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['POST_CUSTOMER_ORDER'], Config['COMMON_CONTROLLER_SUB_URL'], paramSrip, headers, "POST", true);
//             if (stripServerResponse != null && stripServerResponse.data != null && stripServerResponse.status === 200) {
//                 let stripServerResponseDetail = JSON.parse(stripServerResponse.data.data ? stripServerResponse.data.data : "[]");

//                 if (stripServerResponseDetail.length > 0 && stripServerResponseDetail[0].ResponseMsg === "Order Placed Successfully") {
//                     showSuccessMsg("Order Placed Successfully!");

//                     setTimeout(() => {
//                         navigate('/' + getLanguageCodeFromSession() + '/');
//                         dispatch(rootAction.cartAction.setCustomerCart('[]'));
//                         dispatch(rootAction.cartAction.SetTotalCartItems(0));
//                         localStorage.setItem("cartItems", "[]");
//                     }, 1000);
//                 } else {
//                     showErrorMsg("An error occurred. Please try again!");
//                 }
//             } else {
//                 showErrorMsg("An error occurred. Please try again!");
//             }

//             // Optional: Handle any specific actions for payment methods if needed
//             if (PaymentMethod === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
//                 HandleStripCardModal();
//             } else if (PaymentMethod === process.env.REACT_APP_PAYPAL_PAYMENT_METHOD) {
//                 HandlePaypalCardModal();
//             }
//         } catch (err) {
//             showErrorMsg("An error occurred. Please try again!");
//             console.log(err.message);
//             if (PaymentMethod === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
//                 HandleStripCardModal();
//             }
//             dispatch(rootAction.commonAction.setLoading(false));
//         }
//     };

//     return (
//         <div style={{ textAlign: 'center', padding: '50px' }}>
//             <h1>Payment Failed</h1>
//             <p>Unfortunately, your payment could not be processed. Please try again.</p>
//             <p>You will be redirected shortly. If not, click <button onClick={() => navigate('/')}>here</button>.</p>
//         </div>
//     );
// }



// import { useLocation, useNavigate } from 'react-router-dom';
// import React, { useEffect } from 'react';
// import Config from '../../../helpers/Config';
// import rootAction from '../../../stateManagment/actions/rootAction';
// import { useSelector, useDispatch } from 'react-redux';
// import { showSuccessMsg, showErrorMsg } from '../../../helpers/ValidationHelper'; // Make sure these helpers are imported
// import { MakeApiCallAsync } from '../../../helpers/ApiHelpers'; // Make sure this is imported
// import { getLanguageCodeFromSession } from '../../../helpers/CommonHelper'; // Ensure this is imported

// export default function PayUFailResponse() {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const location = useLocation();

//     const loginUser = useSelector(state => JSON.parse(state.userReducer.user || "{}"));
//     const cartItemsSession = useSelector(state => JSON.parse(state.cartReducer.cartItems || "[]"));

//     const OrderNote = ''; // Define as needed
//     const IsCouponCodeApplied = false; // Define based on your logic
//     const CouponCode = ''; // Define based on your logic
//     const PaymentMethod = 6; // Adjust this according to your logic

//     const getQueryParams = () => {
//         const query = new URLSearchParams(location.search);
//         const params = {};
//         query.forEach((value, key) => {
//             params[key] = value;
//         });
//         return params;
//     };

//     useEffect(() => {
//         const params = getQueryParams();
//         console.log("Query Parameters:", params.status);
//         console.log("Query Parameters:", params.unmappedstatus);

//         if (params.status === "success") {
//             dispatch(rootAction.commonAction.setLoading(true));
//             PlaceAndConfirmCustomerOrder().finally(() => {
//                 dispatch(rootAction.commonAction.setLoading(false));

//             });
//         }
//         else{
//             showErrorMsg(params.unmappedstatus)
//         }
//     }, [location]);

//     const PlaceAndConfirmCustomerOrder = async () => {
//         if (!loginUser.UserID) {
//             showErrorMsg("User not logged in.");
//             return;
//         }

//         if (!cartItemsSession.length) {
//             showErrorMsg("Cart is empty.");
//             return;
//         }

//         try {
//             const headers = {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//             };

//             const paramSrip = {
//                 requestParameters: {
//                     UserID: loginUser.UserID,
//                     OrderNote: OrderNote,
//                     cartJsonData: JSON.stringify(cartItemsSession),
//                     CouponCode: IsCouponCodeApplied ? CouponCode : "",
//                     PaymentMethod: PaymentMethod,
//                     paymentToken: "",
//                     payPalOrderConfirmJson: "",
//                     recordValueJson: "[]",
//                 },
//             };

//             const stripServerResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['POST_CUSTOMER_ORDER'], Config['COMMON_CONTROLLER_SUB_URL'], paramSrip, headers, "POST", true);

//             if (stripServerResponse?.data && stripServerResponse.status === 200) {
//                 const responseData = JSON.parse(stripServerResponse.data.data || "[]");
//                 if (responseData.length > 0 && responseData[0].ResponseMsg === "Order Placed Successfully") {
//                     showSuccessMsg("Order Placed Successfully!");
//                     setTimeout(() => {
//                         navigate('/' + getLanguageCodeFromSession() + '/');
//                         dispatch(rootAction.cartAction.setCustomerCart('[]'));
//                         dispatch(rootAction.cartAction.SetTotalCartItems(0));
//                         localStorage.setItem("cartItems", "[]");
//                     }, 1000);
//                 } else {
//                     showErrorMsg("An error occurred. Please try again!");
//                 }
//             } else {
//                 showErrorMsg("An error occurred. Please try again!");
//             }

//         } catch (err) {
//             showErrorMsg("An error occurred. Please try again!");
//             console.error(err.message);
//         }
//     };

//     return (
//         <div style={{ textAlign: 'center', padding: '50px' }}>
//             <h1>Payment Failed</h1>
//             <p>Unfortunately, your payment could not be processed. Please try again.</p>
//             <p>You will be redirected shortly. If not, click <button onClick={() => navigate('/')}>here</button>.</p>
//         </div>
//     );
// }




// import { useLocation, useNavigate } from 'react-router-dom';
// import React, { useEffect, useRef, useState } from 'react';
// import Config from '../../../helpers/Config';
// import rootAction from '../../../stateManagment/actions/rootAction';
// import { useSelector, useDispatch } from 'react-redux';
// import { showSuccessMsg, showErrorMsg } from '../../../helpers/ValidationHelper'; 
// import { MakeApiCallAsync } from '../../../helpers/ApiHelpers'; 
// import { getLanguageCodeFromSession } from '../../../helpers/CommonHelper'; 

// export default function PayUFailResponse() {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const location = useLocation();
//     const hasOrderedRef = useRef(false); // Ref to track if order has been placed
//     const {paramsData,setParamsdata}=useState()

//     const loginUser = useSelector(state => JSON.parse(state.userReducer.user || "{}"));
//     const cartItemsSession = useSelector(state => JSON.parse(state.cartReducer.cartItems || "[]"));

//     const OrderNote = ''; // Define as needed
//     const IsCouponCodeApplied = false; // Define based on your logic
//     const CouponCode = ''; // Define based on your logic
//     const PaymentMethod = 6; // Adjust this according to your logic

//     const getQueryParams = () => {
//         const query = new URLSearchParams(location.search);
//         const params = {};
//         query.forEach((value, key) => {
//             params[key] = value;
//         });
//         setParamsdata(params)
//         return params;
//     };

//     useEffect(() => {
//         const params = getQueryParams();
//         console.log("Query Parameters:", params.status);
//         console.log("Query Parameters:", params.unmappedstatus);

//         if (params.status === "success" && !hasOrderedRef.current) {
//             hasOrderedRef.current = true; // Set to true to prevent further calls
//             dispatch(rootAction.commonAction.setLoading(true));
//             PlaceAndConfirmCustomerOrder().finally(() => {
//                 dispatch(rootAction.commonAction.setLoading(false));
//             });
//         } else {
//             showErrorMsg(params.unmappedstatus);
//         }
//     }, [location]);

//     const PlaceAndConfirmCustomerOrder = async () => {
//         if (!loginUser.UserID) {
//             showErrorMsg("User not logged in.");
//             return;
//         }

//         if (!cartItemsSession.length) {
//             showErrorMsg("Cart is empty.");
//             return;
//         }

//         try {
//             const headers = {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//             };

//             const paramSrip = {
//                 requestParameters: {
//                     UserID: loginUser.UserID,
//                     OrderNote: OrderNote,
//                     cartJsonData: JSON.stringify(cartItemsSession),
//                     CouponCode: IsCouponCodeApplied ? CouponCode : "",
//                     PaymentMethod: PaymentMethod,
//                     paymentToken: "",
//                     payPalOrderConfirmJson: "",
//                     recordValueJson: "[]",
//                 },
//             };

//             const stripServerResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['POST_CUSTOMER_ORDER'], Config['COMMON_CONTROLLER_SUB_URL'], paramSrip, headers, "POST", true);

//             if (stripServerResponse?.data && stripServerResponse.status === 200) {
//                 const responseData = JSON.parse(stripServerResponse.data.data || "[]");
//                 if (responseData.length > 0 && responseData[0].ResponseMsg === "Order Placed Successfully") {
//                     showSuccessMsg("Order Placed Successfully!");
//                     setTimeout(() => {
//                         navigate('/' + getLanguageCodeFromSession() + '/');
//                         dispatch(rootAction.cartAction.setCustomerCart('[]'));
//                         dispatch(rootAction.cartAction.SetTotalCartItems(0));
//                         localStorage.setItem("cartItems", "[]");
//                     }, 1000);
//                 } else {
//                     showErrorMsg("An error occurred. Please try again!");
//                 }
//             } else {
//                 showErrorMsg("An error occurred. Please try again!");
//             }
//         } catch (err) {
//             showErrorMsg("An error occurred. Please try again!");
//             console.error(err.message);
//         }
//     };

//     return (
//         <div style={{ textAlign: 'center', padding: '50px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
//         <h1 style={{ color: paramsData.status === "success" ? 'green' : 'red' }}>
//             {paramsData.status === "success" ? "Payment Successful" : "Payment Failed"}
//         </h1>
//         <p>
//             {paramsData.status === "success" 
//                 ? "Thank you for your order! Your payment has been processed successfully." 
//                 : "Unfortunately, your payment could not be processed. Please try again."
//             }
//         </p>
//         <p>
//             You will be redirected shortly. If not, click 
//             <button onClick={() => navigate('/')} style={{ marginLeft: '5px', color: '#007bff', background: 'none', border: 'none', textDecoration: 'underline', cursor: 'pointer' }}>
//                 here
//             </button>.
//         </p>
//     </div>
//     );
// }


import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';
import { useSelector, useDispatch } from 'react-redux';
import { showSuccessMsg, showErrorMsg } from '../../../helpers/ValidationHelper';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import { getLanguageCodeFromSession } from '../../../helpers/CommonHelper';
import './PayUFailResponse.css'; 
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; 

export default function PayUFailResponse() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const hasOrderedRef = useRef(false);

    const [paramsData, setParamsData] = useState({}); 

    const loginUser = useSelector(state => JSON.parse(state.userReducer.user || "{}"));
    const cartItemsSession = useSelector(state => JSON.parse(state.cartReducer.cartItems || "[]"));

    const OrderNote = ''; 
    const IsCouponCodeApplied = false; 
    const CouponCode = ''; 
    const PaymentMethod = 7; 

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const params = {};
        query.forEach((value, key) => {
            params[key] = value;
        });
        setParamsData(params);
        
        console.log("Query Parameters:", params.status);
        console.log("Query Parameters:", params.unmappedstatus);
    
        if (params.status === "success" && params.mode==!null &&  params.unmappedstatus==!"userCancelled" && !hasOrderedRef.current) {
            hasOrderedRef.current = true; 
            dispatch(rootAction.commonAction.setLoading(true));
            PlaceAndConfirmCustomerOrder().finally(() => {
                dispatch(rootAction.commonAction.setLoading(false));
            });
        } else {
            showErrorMsg(params.unmappedstatus || "Payment failed. Please try again.");
        }
    }, [location]);
    
    
    const PlaceAndConfirmCustomerOrder = async () => {
        if (!loginUser.UserID) {
            showErrorMsg("User not logged in.");
            return;
        }

        if (!cartItemsSession.length) {
            showErrorMsg("Cart is empty.");
            return;
        }

        try {
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };

            const paramSrip = {
                requestParameters: {
                    UserID: loginUser.UserID,
                    OrderNote: OrderNote,
                    cartJsonData: JSON.stringify(cartItemsSession),
                    CouponCode: IsCouponCodeApplied ? CouponCode : "",
                    PaymentMethod: PaymentMethod,
                    paymentToken: "",
                    payPalOrderConfirmJson: "",
                    recordValueJson: "[]",
                },
            };

            const stripServerResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['POST_CUSTOMER_ORDER'], Config['COMMON_CONTROLLER_SUB_URL'], paramSrip, headers, "POST", true);

            if (stripServerResponse?.data && stripServerResponse.status === 200) {
                const responseData = JSON.parse(stripServerResponse.data.data || "[]");
                if (responseData.length > 0 && responseData[0].ResponseMsg === "Order Placed Successfully") {
                    showSuccessMsg("Order Placed Successfully!");
                    setTimeout(() => {
                        navigate('/' + getLanguageCodeFromSession() + '/');
                        dispatch(rootAction.cartAction.setCustomerCart('[]'));
                        dispatch(rootAction.cartAction.SetTotalCartItems(0));
                        localStorage.setItem("cartItems", "[]");
                    }, 1000);
                } else {
                    showErrorMsg("An error occurred. Please try again!");
                }
            } else {
                showErrorMsg("An error occurred. Please try again!");
            }
        } catch (err) {
            showErrorMsg("An error occurred. Please try again!");
            console.error(err.message);
        }
    };

    return (
        <div className="message-container">
            <div className={`message-card ${paramsData.status === "success" &&  paramsData.unmappedstatus==!"userCancelled" ? "success" : "error"}`}>
                <div className={`icon2 ${paramsData.status === "success" &&  paramsData.unmappedstatus==!"userCancelled" ? "success-icon" : "error-icon"}`}>
                    {paramsData.status === "success" &&  paramsData.unmappedstatus==!"userCancelled" ? <FaCheckCircle /> : <FaTimesCircle />}
                </div>
                <h1>
                    {paramsData.status === "success" &&  paramsData.unmappedstatus==!"userCancelled" ? "Payment Successful" : "Payment Failed"}
                </h1>
                <p>
                    {paramsData.status === "success"&&  paramsData.unmappedstatus==!"userCancelled"
                        ? "Thank you for your order! Your payment has been processed successfully."
                        : "Unfortunately, your payment could not be processed. Please try again."
                    }
                </p>
                <p className="redirect-text">
                    You will be redirected shortly. If not, click
                    {paramsData.status === "success"

                        ? <button onClick={() => navigate('/orders-history')} className="redirect-button">
                            here
                        </button>
                        : <button onClick={() => navigate('/cart')} className="redirect-button">
                            here
                        </button>
                    }
                </p>
            </div>
        </div>
    );
}
