// import React, { useEffect, useState } from "react";

// import Config from "../../../helpers/Config";

// import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";


// function DistributorsContent() {
//   const [productDetail, setProductDetail] = useState({});
   
//   useEffect(() => {
//     // declare the data fetching function
//     const getProductDetail = async () => {
      
//         //--Get product detail
//         const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_PRODUCT_DETAIL'], null, "POST", true);
//         if (response != null && response.data != null) {
//             let detail = JSON.parse(response.data.data);
//             console.log("Product detail: ");
//             console.log(detail);
//             await setProductDetail(detail[0]);
           
//         }

//     }

// }, [])  




//   return (
//     <>
//                                                            <div className="">
//                                                         <h1 className="">Vendor:</h1>
//                                                         <h1 className="">{productDetail?.VendorName}</h1>
//                                                     </div>   
//     </>
//   )
// }

// export default DistributorsContent


import React, { useEffect, useState } from "react";
import Config from "../../../helpers/Config";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";

function DistributorsContent(VendorName) {
 
  return (
    <>
      <div className="">
        <h1 className="">Vendor:</h1>
        <h1 className="">{VendorName}</h1>
      </div>
    </>
  );
}

export default DistributorsContent;
