// import React, { useContext, useEffect, useRef, useState } from "react";
// import Slider from "react-slick";
// import {  Row, Col, Media } from "reactstrap";
// import Config from "../../../helpers/Config";
// import { getFileExtensionNameFromPath } from "../../../helpers/ConversionHelper";
// import ReactImageMagnify from "react-image-magnify";

// const ProductDetailImages = (props) => {
//     const slider1 = useRef(Slider);
//     const slider2 = useRef(Slider);
//     const [state, setState] = useState({ nav1: null, nav2: null });
//     const [imagesList, setImagesList] = useState([]);
//     const [adminPanelBaseURL, setadminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);

//     useEffect(() => {
//         setState({
//             nav1: slider1.current,
//             nav2: slider2.current,
//         });
//     }, []);
//     const { nav1, nav2 } = state;





//     const MakeImageList = () => {

//         let arrayData = [];
//         if(props.ProductImages!=undefined && props.ProductImages!=null && props.ProductImages.length>0){

//             for (let i = 0; i < props.ProductImages.length; i++) {

//                 arrayData.push({
//                     __typename : getFileExtensionNameFromPath(),
//                     alt:"product detail image " + i,
//                     src: (adminPanelBaseURL+ props.ProductImages[i].AttachmentURL),
//                 });
//             }
//         }

//         setImagesList(arrayData);

//     }

//     useEffect(() => {
//         MakeImageList();
//     }, [props.ProductImages])


//     return (


//         <>
//             <Slider className="product-slick" asNavFor={nav2} ref={(slider) => (slider1.current = slider)}>
//                 {imagesList &&
//                     imagesList.map((img, i) => {
//                         return (
//                             <div key={i}>
//                                 {/* <Media src={img.src} alt="" className="img-fluid  image_zoom_cls-0" /> */}
//                                 <ReactImageMagnify
//                                 {...{
//                                     smallImage:{
//                                         alt: 'Wristwatch by Ted Baker London',
//                                         isFluidWidth: false,
//                                         src: img.src,
//                                     },
//                                     largeImage: {
//                                         src:  img.src,
//                                         width: 1200,
//                                         height: 1800
//                                       }
//                                 }}

//                                 />
//                             </div>
//                         );
//                     })}
//             </Slider>
//             <Row>
//                 <Col>
//                     <Slider className="slider-nav" asNavFor={nav1} ref={(slider) => (slider2.current = slider)} slidesToShow={3} swipeToSlide={true} focusOnSelect={true} arrows={false} adaptiveHeight={true}>
//                         {imagesList &&
//                             imagesList.map((img, i) => {
//                                 return (
//                                     <div key={i}>
//                                         <Media src={img.src} alt="" className="img-fluid  image_zoom_cls-0" />
//                                     </div>
//                                 );
//                             })}
//                     </Slider>
//                 </Col>
//             </Row>
//         </>
//     );


// }
// export default ProductDetailImages;



// import React, { useEffect, useRef, useState } from "react";
// import Slider from "react-slick";
// import { Row, Col } from "reactstrap";
// import Config from "../../../helpers/Config";
// import { getFileExtensionNameFromPath } from "../../../helpers/ConversionHelper";
// import ReactImageMagnify from "react-image-magnify";

// const ProductDetailImages = (props) => {
//     const slider1 = useRef(null);
//     const slider2 = useRef(null);
//     const [state, setState] = useState({ nav1: null, nav2: null });
//     const [imagesList, setImagesList] = useState([]);
//     const [adminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);

//     useEffect(() => {
//         setState({
//             nav1: slider1.current,
//             nav2: slider2.current,
//         });
//     }, []);

//     const MakeImageList = () => {
//         let arrayData = [];
//         if (props.ProductImages && props.ProductImages.length > 0) {
//             arrayData = props.ProductImages.map((image, i) => ({
//                 __typename: getFileExtensionNameFromPath(image.AttachmentURL),
//                 alt: "product detail image " + i,
//                 src: adminPanelBaseURL + image.AttachmentURL,
//             }));
//         }
//         setImagesList(arrayData);
//     };

//     useEffect(() => {
//         MakeImageList();
//     }, [props.ProductImages]);

//     return (
//         <>
//             <Slider className="product-slick" asNavFor={state.nav2} ref={slider1}>
//                 {imagesList.map((img, i) => (
//                     <div key={i}>
//                         <ReactImageMagnify
//                             {...{
//                                 smallImage: {
//                                     alt: 'Product Image',
//                                     isFluidWidth: true,
//                                     src: img.src,
//                                 },
//                                 largeImage: {
//                                     src: img.src,
//                                     width: 1200,
//                                     height: 1800,
//                                 }
//                             }}
//                         />
//                     </div>
//                 ))}
//             </Slider>
//             <Row>
//                 <Col>
//                     <Slider
//                         className="slider-nav"
//                         asNavFor={state.nav1}
//                         ref={slider2}
//                         slidesToShow={3}
//                         swipeToSlide={true}
//                         focusOnSelect={true}
//                         arrows={false}
//                         adaptiveHeight={true}
//                     >
//                         {imagesList.map((img, i) => (
//                             <div key={i}>
//                                 <img src={img.src} alt={img.alt} className="img-fluid" />
//                             </div>
//                         ))}
//                     </Slider>
//                 </Col>
//             </Row>
//         </>
//     );
// };

// export default ProductDetailImages;



//👉👉 This Code Working well👈

// import React, { useEffect, useRef, useState } from "react";
// import Slider from "react-slick";
// import { Row, Col } from "reactstrap";
// import Config from "../../../helpers/Config";
// import { getFileExtensionNameFromPath } from "../../../helpers/ConversionHelper";
// import ReactImageMagnify from "react-image-magnify";

// const ProductDetailImages = (props) => {
//     const slider1 = useRef(null);
//     const slider2 = useRef(null);
//     const [state, setState] = useState({ nav1: null, nav2: null });
//     const [imagesList, setImagesList] = useState([]);
//     const [adminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);

//     useEffect(() => {
//         setState({
//             nav1: slider1.current,
//             nav2: slider2.current,
//         });
//     }, []);

//     const MakeImageList = () => {
//         let arrayData = [];
//         if (props.ProductImages && props.ProductImages.length > 0) {
//             arrayData = props.ProductImages.map((image, i) => ({
//                 __typename: getFileExtensionNameFromPath(image.AttachmentURL),
//                 alt: "product detail image " + i,
//                 src: adminPanelBaseURL + image.AttachmentURL,
//             }));
//         }
//         setImagesList(arrayData);
//     };

//     useEffect(() => {
//         MakeImageList();
//     }, [props.ProductImages]);

//     return (
//         <>
//             <Slider className="product-slick" asNavFor={state.nav2} ref={slider1}>
//                 {imagesList.map((img, i) => (
//                     <div key={i} style={{ position: 'relative' }}>
//                         <ReactImageMagnify
//                             {...{
//                                 smallImage: {
//                                     alt: img.alt,
//                                     isFluidWidth: false,
//                                     src: img.src,
//                                     width:300,
//                                     height: 400
//                                 },
//                                 largeImage: {
//                                     src: img.src,
//                                     width: 1200,
//                                     height: 1800,
//                                 },
//                                 enlargedImageContainerDimensions: {
//                                     width: '200%',
//                                     height: '100%',
//                                 },
//                                 enlargedImageContainerStyle: {
//                                     position: 'absolute',
//                                     zIndex: 9,
//                                 },
//                                 shouldUsePositiveSpaceLens: true,
//                             }}
//                         />
//                     </div>
//                 ))}
//             </Slider>
//             <Row>
//                 <Col>
//                     <Slider
//                         className="slider-nav"
//                         asNavFor={state.nav1}
//                         ref={slider2}
//                         slidesToShow={3}
//                         swipeToSlide={true}
//                         focusOnSelect={true}
//                         arrows={false}
//                         adaptiveHeight={true}
//                     >
//                         {imagesList.map((img, i) => (
//                             <div key={i}>
//                                 <img src={img.src} alt={img.alt} className="img-fluid" />
//                             </div>
//                         ))}
//                     </Slider>
//                 </Col>
//             </Row>
//         </>
//     );
// };

// export default ProductDetailImages;





// import React, { useEffect, useRef, useState } from "react";
// import Slider from "react-slick";
// import { Row, Col } from "reactstrap";
// import Config from "../../../helpers/Config";
// import { getFileExtensionNameFromPath } from "../../../helpers/ConversionHelper";
// import ReactImageMagnify from "react-image-magnify";
// // import "./ProductDetailImage.css";

// const ProductDetailImages = (props) => {
//     const slider1 = useRef(null);
//     const slider2 = useRef(null);
//     const [state, setState] = useState({ nav1: null, nav2: null });
//     const [imagesList, setImagesList] = useState([]);
//     const [selectedImageIndex, setSelectedImageIndex] = useState(0); // Track selected image index
//     const [adminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);

//     useEffect(() => {
//         setState({
//             nav1: slider1.current,
//             nav2: slider2.current,
//         });
//     }, []);

//     useEffect(() => {
//         const MakeImageList = () => {
//             let arrayData = [];
//             if (props.ProductImages && props.ProductImages.length > 0) {
//                 arrayData = props.ProductImages.map((image, i) => ({
//                     __typename: getFileExtensionNameFromPath(image.AttachmentURL),
//                     alt: "product detail image " + i,
//                     src: adminPanelBaseURL + image.AttachmentURL,
//                 }));
//             }
//             setImagesList(arrayData);
//         };

//         MakeImageList();
//     }, [props.ProductImages]);

//     // Handle thumbnail click to change selected image
//     const handleThumbnailClick = (index) => {
//         setSelectedImageIndex(index);
//         if (slider1.current) {
//             slider1.current.slickGoTo(index); // Synchronize main image with thumbnail
//         }
//     };

//     return (
//         <>
//             <div className="container">
//                 <div className="row">
//                     <div className="col-12">
//                         {imagesList.length > 0 && (
//                             <ReactImageMagnify
//                                 {...{
//                                     smallImage: {
//                                         alt: imagesList[selectedImageIndex]?.alt,
//                                         isFluidWidth: true,
//                                         src: imagesList[selectedImageIndex]?.src,
//                                         width: 1000,
//                                         height: 1000
//                                     },
//                                     largeImage: {
//                                         src: imagesList[selectedImageIndex]?.src,
//                                         width: 1200,
//                                         height: 1200
//                                     },

//                                     enlargedImageContainerStyle: {
//                                         position: 'absolute',
//                                         zIndex: 1000,
//                                         overflow: 'hidden',


//                                     },
//                                     // lensStyle:{backgroundColor:"rgba(0,0,0,.9)"},
//                                     shouldUsePositiveSpaceLens: true,

//                                 }}
//                             />
//                         )}
//                     </div>
//                 </div>
//             </div>
//             <Row>
//                 <Col>
//                     <Slider
//                         className="slider-nav"
//                         asNavFor={state.nav1}
//                         ref={slider2}
//                         slidesToShow={3}
//                         swipeToSlide={true}
//                         focusOnSelect={true}
//                         arrows={false}
//                         adaptiveHeight={true}
//                     >
//                         {imagesList.map((img, i) => (
//                             <div key={i} className="thumbnail">
//                                 <img
//                                     src={img.src}
//                                     alt={img.alt}
//                                     className="img-fluid"
//                                     onClick={() => handleThumbnailClick(i)} // Set the selected image index on click
//                                     style={{ cursor: 'pointer' }} // Change cursor to pointer
//                                 />
//                             </div>
//                         ))}
//                     </Slider>
//                 </Col>
//             </Row>
//         </>
//     );
// };

// export default ProductDetailImages;









// import React, { useEffect, useRef, useState } from "react";
// import Slider from "react-slick";
// import { Row, Col } from "reactstrap";
// import Config from "../../../helpers/Config";
// import { getFileExtensionNameFromPath } from "../../../helpers/ConversionHelper";
// import ReactImageMagnify from "react-image-magnify";

// const ProductDetailImages = (props) => {
//     const slider1 = useRef(null);
//     const slider2 = useRef(null);
//     const [state, setState] = useState({ nav1: null, nav2: null });
//     const [imagesList, setImagesList] = useState([]);
//     const [selectedImageIndex, setSelectedImageIndex] = useState(0);
//     const [adminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);

//     useEffect(() => {
//         setState({
//             nav1: slider1.current,
//             nav2: slider2.current,
//         });
//     }, []);

//     useEffect(() => {
//         const MakeImageList = () => {
//             let arrayData = [];
//             if (props.ProductImages && props.ProductImages.length > 0) {
//                 arrayData = props.ProductImages.map((image, i) => ({
//                     __typename: getFileExtensionNameFromPath(image.AttachmentURL),
//                     alt: "product detail image " + i,
//                     src: adminPanelBaseURL + image.AttachmentURL,
//                 }));
//             }
//             setImagesList(arrayData);
//         };

//         MakeImageList();
//     }, [props.ProductImages]);

//     const handleThumbnailClick = (index) => {
//         setSelectedImageIndex(index);
//         if (slider1.current) {
//             slider1.current.slickGoTo(index);
//         }
//     };

//     return (
//         <>
//             <div className="container">
//                 <div className="row">
//                     <div className="col-12">
//                         {imagesList.length > 0 && (
//                             <div style={{ position: 'relative' }}>
//                                 <ReactImageMagnify
//                                     {...{
//                                         smallImage: {
//                                             alt: imagesList[selectedImageIndex]?.alt,
//                                             isFluidWidth: false,
//                                             src: imagesList[selectedImageIndex]?.src,
//                                             width: 400,
//                                             height: 400
//                                         },
//                                         largeImage: {
//                                             src: imagesList[selectedImageIndex]?.src,
//                                             width: 1200,
//                                             height: 1200
//                                         },
//                                         enlargedImageContainerStyle: {
//                                             position: 'absolute',
//                                             zIndex: 1000,
//                                             overflow: 'hidden',
//                                             width: 'auto',
//                                             height: 'auto',
//                                         },
//                                         shouldUsePositiveSpaceLens: true,
//                                     }}
//                                 />
//                                 <div style={{
//                                     position: 'absolute',
//                                     top: '10px',
//                                     right: '10px',
//                                     zIndex: 1001,
//                                 }}>
//                                     <i className="fa fa-heart" style={{ fontSize: '24px', color: 'red', cursor: 'pointer' }} onClick={() => alert('Icon Clicked!')}></i>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </div>
//             <Row>
//                 <Col>
//                     <Slider
//                         className="slider-nav"
//                         asNavFor={state.nav1}
//                         ref={slider2}
//                         slidesToShow={3}
//                         swipeToSlide={true}
//                         focusOnSelect={true}
//                         arrows={false}
//                         adaptiveHeight={true}
//                     >
//                         {imagesList.map((img, i) => (
//                             <div key={i} className="thumbnail">
//                                 <img
//                                     src={img.src}
//                                     alt={img.alt}
//                                     className="img-fluid"
//                                     onClick={() => handleThumbnailClick(i)}
//                                     style={{ cursor: 'pointer', height: "150px" }}
//                                 />
//                             </div>
//                         ))}
//                     </Slider>
//                 </Col>
//             </Row>
//         </>
//     );
// };

// export default ProductDetailImages;



import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Row, Col } from "reactstrap";
import Config from "../../../helpers/Config";
import { getFileExtensionNameFromPath } from "../../../helpers/ConversionHelper";
import ReactImageMagnify from "react-image-magnify";
import "./modifyImage.css"
const ProductDetailImages = (props) => {
    const slider1 = useRef(null);
    const slider2 = useRef(null);
    const [state, setState] = useState({ nav1: null, nav2: null });
    const [imagesList, setImagesList] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [adminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);

    useEffect(() => {
        setState({
            nav1: slider1.current,
            nav2: slider2.current,
        });
    }, []);

    useEffect(() => {
        const MakeImageList = () => {
            let arrayData = [];
            if (props.ProductImages && props.ProductImages.length > 0) {
                arrayData = props.ProductImages.map((image, i) => ({
                    __typename: getFileExtensionNameFromPath(image.AttachmentURL),
                    alt: "product detail image " + i,
                    src: adminPanelBaseURL + image.AttachmentURL,
                }));
            }
            setImagesList(arrayData);
        };

        MakeImageList();
    }, [props.ProductImages]);

    const handleThumbnailClick = (index) => {
        setSelectedImageIndex(index);
        if (slider1.current) {
            slider1.current.slickGoTo(index);
        }
    };
    const isMobile = window.innerWidth < 1365;
    const isMobile2 = window.innerWidth < 1050;

    // Set small image dimensions based on screen size
    const smallImageWidth = isMobile2 ? 280 : (isMobile ? 300 : 400);
    const smallImageHeight = isMobile2 ? 280 : (isMobile ? 300 : 400);


    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {imagesList.length > 0 && (
                            <>
                                <div className="show1">
                                    <img src={imagesList[selectedImageIndex]?.src} className="img-fluid" />
                                </div>
                                <div className="show2">
                                    <ReactImageMagnify
                                        {...{
                                            smallImage: {
                                                alt: imagesList[selectedImageIndex]?.alt,
                                                isFluidWidth: false, // Change to false if you want to set specific dimensions
                                                src: imagesList[selectedImageIndex]?.src,
                                                width: smallImageWidth, // Adjust width as needed
                                                height: smallImageHeight // Adjust height as needed
                                            },
                                            largeImage: {
                                                src: imagesList[selectedImageIndex]?.src,
                                                width: 1200,
                                                height: 1200
                                            },
                                            enlargedImageContainerStyle: {
                                                position: 'absolute',
                                                zIndex: 1000,
                                                overflow: 'hidden',
                                                width: 'auto',
                                                height: 'auto',
                                            },
                                            shouldUsePositiveSpaceLens: true,
                                            className: 'react-image-magnify' // Add this line

                                        }}
                                    />
                                </div>
                            </>

                        )}
                    </div>
                </div>
            </div>
            <Row>
                <Col>
                    <Slider
                        className="slider-nav"
                        asNavFor={state.nav1}
                        ref={slider2}
                        slidesToShow={3}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={false}
                        adaptiveHeight={true}
                    >
                        {imagesList.map((img, i) => (
                            <div key={i} className="thumbnail">
                                <img
                                    src={img.src}
                                    alt={img.alt}
                                    className="img-fluid"
                                    onClick={() => handleThumbnailClick(i)}
                                    style={{ cursor: 'pointer', height: "150px" }}
                                />
                            </div>
                        ))}
                    </Slider>
                </Col>
            </Row>
        </>
    );
};

export default ProductDetailImages;