import React from 'react';
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import "./CartStar.css"

export default function CartStar({ star }) {
    const ratingStar = Array.from({ length: 5 }, (elem, index) => {
        const number = index + 0.5;
        return (
            <span key={index}>
                {star >= index + 1
                    ? <FaStar className='icon' />
                    : star >= number
                        ? <FaStarHalfAlt className='icon' />
                        : <AiOutlineStar className='icon' />
                }
            </span>
        );
    });

    return (
        <div>
            <div className="icon-style">
                {ratingStar}
            </div>
        </div>
    );
}
